<template>
  <v-combobox
    v-model="selectedValue"
    :label="label"
    :items="items"
    :multiple="multiple"
    :chips="chips"
    :deletable-chips="deletableChips"
    :clearable="clearable"
    :disabled="disabled"
    :menu-props="{ offsetY: true }"
    :rules="validationRules"
    item-text="name"
    item-value="id"
    hide-details="auto"
    outlined
    dense
    @input="onInput"
    @change="onChange"
  >
    <template v-if="multiple" v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0">
        <span>{{ item.name ? item.name : item }}</span>
      </v-chip>
      <span v-if="index === 1" class="grey--text text-caption">
        +{{ value.length - 1 }} others
      </span>
    </template>
  </v-combobox>
</template>
<script>
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'AcComboBox',
  props: {
    value: {
      type: [String, Number, Array, Object],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validationRules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const selectedValue = ref(props.value)

    const onInput = val => {
      emit('input', val)
    }
    const onChange = val => {
      emit('change', val)
    }

    watch(
      () => props.value,
      val => (selectedValue.value = val),
    )

    return { selectedValue, onChange, onInput }
  },
}
</script>

<style lang="scss" scoped></style>
